<template>
  <div class="container h-75 products">
    <div class="products-content">
      <div
        v-if="screenWidth >= 992"
        class="products-content-title d-flex align-items-end"
        :class="tilteAnimate"
      >
        <div class="d-flex">
          <div class="colon"></div>
          <div class="products-content-title-icon page-title">极致产品</div>
        </div>
        <span class="words opacity-1 mb-1">多年沉淀只做业内顶尖</span>
      </div>
      <div class="products-card">
        <div class="products-bgi" :style="`background-image:url(${background})`"></div>
        <swiper ref="mySwiper" @slideChange="backgroundChange" :options="swiperOption">
          <swiper-slide>
            <h3 class="products-card-title">原生精品定制</h3>
            <ul class="pl-0">
              <li class="products-card-content">
                <div class="products-icon products-icon1-1"></div>
                <div>
                  <h4 class="products-card-main">产品原生打造</h4>
                  <p class="products-card-sub">
                    PC、APP、H5三端产品纯原生打造，设计精美，体验极致流畅
                  </p>
                </div>
              </li>
              <li class="products-card-content">
                <div class="products-icon products-icon1-2"></div>
                <div>
                  <h4 class="products-card-main">主题风格定制</h4>
                  <p class="products-card-sub">
                    根据行业用户、平台目标用户群体，精细化定制平台的主题风格
                  </p>
                </div>
              </li>
              <li class="products-card-content">
                <div class="products-icon products-icon1-3"></div>
                <div>
                  <h4 class="products-card-main">专属品牌制作</h4>
                  <p class="products-card-sub">
                    业界资深设计团队，量身定制品牌LOGO，全面提升品牌形象
                  </p>
                </div>
              </li>
              <li class="products-card-content">
                <div class="products-icon products-icon1-4"></div>
                <div>
                  <h4 class="products-card-main">丰富主题模板</h4>
                  <p class="products-card-sub">
                    精致模板匠心制作，丰富主题在线选择，签约即可快速布局上线
                  </p>
                </div>
              </li>
            </ul>
          </swiper-slide>
          <swiper-slide>
            <h3 class="products-card-title">自研支付系统</h3>
            <ul class="pl-0">
              <li class="products-card-content">
                <div class="products-icon products-icon2-1"></div>
                <div>
                  <h4 class="products-card-main">USDT 数字货币交易</h4>
                  <p class="products-card-sub">
                    全天候交易不受银行监管，价格稳定交易无上限
                  </p>
                </div>
              </li>
              <li class="products-card-content">
                <div class="products-icon products-icon2-2"></div>
                <div>
                  <h4 class="products-card-main">代理代存功能</h4>
                  <p class="products-card-sub">
                    超智能风控系统，风控规则即点即用，轻松反欺诈
                  </p>
                </div>
              </li>
              <li class="products-card-content">
                <div class="products-icon products-icon2-3"></div>
                <div>
                  <h4 class="products-card-main">专属优惠渠道</h4>
                  <p class="products-card-sub">专属高质量支付渠道，低费率、超安全</p>
                </div>
              </li>
              <li class="products-card-content">
                <div class="products-icon products-icon2-4"></div>
                <div>
                  <h4 class="products-card-main">超便捷存取款</h4>
                  <p class="products-card-sub">超千种支付接口，快速存取金额，秒提秒到</p>
                </div>
              </li>
            </ul>
          </swiper-slide>
          <swiper-slide>
            <h3 class="products-card-title">稳定后台系统</h3>
            <ul class="pl-0">
              <li class="products-card-content">
                <div class="products-icon products-icon3-1"></div>
                <div>
                  <h4 class="products-card-main">代理团队模式</h4>
                  <p class="products-card-sub">独有代理组织管理、结算管理，一目了然</p>
                </div>
              </li>
              <li class="products-card-content">
                <div class="products-icon products-icon3-2"></div>
                <div>
                  <h4 class="products-card-main">代理代存功能</h4>
                  <p class="products-card-sub">代理为玩家充值，即充即玩，无需等待</p>
                </div>
              </li>
              <li class="products-card-content">
                <div class="products-icon products-icon3-3"></div>
                <div>
                  <h4 class="products-card-main">大数据分析报表</h4>
                  <p class="products-card-sub">
                    全自动数据分析，报表分析，平台运营胸有成竹
                  </p>
                </div>
              </li>
              <li class="products-card-content">
                <div class="products-icon products-icon3-4"></div>
                <div>
                  <h4 class="products-card-main">自定义优惠模板</h4>
                  <p class="products-card-sub">
                    后台系统内置多类型优惠活动，定义数据即可上线
                  </p>
                </div>
              </li>
            </ul>
          </swiper-slide>
          <swiper-slide>
            <h3 class="products-card-title">强大防御系统</h3>
            <ul class="pl-0">
              <li class="products-card-content">
                <div class="products-icon products-icon4-1"></div>
                <div>
                  <h4 class="products-card-main">域名防劫持</h4>
                  <p class="products-card-sub">
                    防劫持覆盖多端设备，保障平台、用户、资金安全
                  </p>
                </div>
              </li>
              <li class="products-card-content">
                <div class="products-icon products-icon4-2"></div>
                <div>
                  <h4 class="products-card-main">超智能风控</h4>
                  <p class="products-card-sub">
                    超智能风控系统，风控规则即点即用，轻松反欺诈
                  </p>
                </div>
              </li>
              <li class="products-card-content">
                <div class="products-icon products-icon4-3"></div>
                <div>
                  <h4 class="products-card-main">超智能风控系统</h4>
                  <p class="products-card-sub">
                    百余人一线专家团队，最大限度保障业务和数据安全
                  </p>
                </div>
              </li>
              <li class="products-card-content">
                <div class="products-icon products-icon4-4"></div>
                <div>
                  <h4 class="products-card-main">数据恢复</h4>
                  <p class="products-card-sub">
                    两地三备份冗余系统，三级容灾，病毒、误删、异常及故障等，秒速恢复
                  </p>
                </div>
              </li>
            </ul>
          </swiper-slide>
          <div class="swiper-pagination swiper-mt" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import productbg1 from "@/assets/images/pro/pro_0.png";
import productbg2 from "@/assets/images/pro/pro_1.png";
import productbg3 from "@/assets/images/pro/pro_2.png";
import productbg4 from "@/assets/images/pro/pro_3.png";

export default {
  name: "Index",
  props: {
    slideData: {
      type: String,
      required: true,
    },
    screenWidth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      background: productbg1,
      swiperOption: {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          stopOnLastSlide: false,
          waitForTransition: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  methods: {
    backgroundChange() {
      switch (this.$refs.mySwiper.$swiper.activeIndex) {
        case 0:
          this.background = productbg1;
          break;
        case 1:
          this.background = productbg2;
          break;
        case 2:
          this.background = productbg3;
          break;
        case 3:
          this.background = productbg4;
          break;
      }
    },
  },
  computed: {
    tilteAnimate() {
      return {
        animate__animated: this.slideData === "products",
        animate__lightSpeedInLeft: this.slideData === "products",
        "opacity-0": this.slideData !== "products",
        "opacity-1": this.slideData === "products",
      };
    },
  },
};
</script>
