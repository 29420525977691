<template>
  <div class="container h-75 game">
    <div class="game-content">
      <div v-if="screenWidth >= 992" class="game-content-title d-flex align-items-end" :class="tilteAnimate">
        <div class="d-flex">
          <div class="colon"></div>
          <div class="game-content-title-icon page-title">游戏中心</div>
        </div>
        <span class="words opacity-1 mb-1">甄选业内顶尖游戏 汇集丰富多样玩法</span>
      </div>
      <div class="game-pagination" v-if="screenWidth >= 992">
        <span @click="changePagination(0)" class="game-pagination-icon sport"
          :class="{ 'game-pagination-icon-active': paginationNum === 0 }">体育赛事</span>
        <span @click="changePagination(1)" class="game-pagination-icon slot"
          :class="{ 'game-pagination-icon-active': paginationNum === 1 }">电子游艺</span>
        <span @click="changePagination(2)" class="game-pagination-icon board"
          :class="{ 'game-pagination-icon-active': paginationNum === 2 }">棋牌游戏</span>
        <span @click="changePagination(3)" class="game-pagination-icon lottery"
          :class="{ 'game-pagination-icon-active': paginationNum === 3 }">彩票投注</span>
        <span @click="changePagination(4)" class="game-pagination-icon live"
          :class="{ 'game-pagination-icon-active': paginationNum === 4 }">真人娱乐</span>
        <span @click="changePagination(5)" class="game-pagination-icon esport"
          :class="{ 'game-pagination-icon-active': paginationNum === 5 }">电子竞技</span>
      </div>
      <swiper ref="mySwiper" @slideChange="changePagination($refs.mySwiper.$swiper.activeIndex)" :options="swiperOption">
        <swiper-slide>
          <div class="row">
            <div class="order-last col-lg-6 order-lg-first">
              <div class="game-content-bg game-content-bg-1"></div>
            </div>
            <div class="col-lg-6">
              <div :class="{ 'game-content-card': screenWidth < 992 }">
                <div class="game-content-line"></div>
                <h3 class="game-content-main game-content-main-1"></h3>
                <p class="game-content-sub">
                  我们提供行业领先皇冠体育、沙巴体育、CMD体育、SABA体育、NBB体育、IM体育、AG体育、BB体育、SBO体育、ESB体育、TF体育、AI体育，给玩家呈现最高赔率，最流畅的投注体验。体育竞猜，无视频不精彩。DOING包网提供全网覆盖面最全
                  最高清的赛事视频，客户引流，客户留存，无所不能
                </p>
                <ul class="game-content-list">
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-IBO"></div>
                    <div class="game-content-icon-title">皇冠</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-CMD"></div>
                    <div class="game-content-icon-title">CMD</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-SB"></div>
                    <div class="game-content-icon-title">SABA</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-NBB"></div>
                    <div class="game-content-icon-title">NBB</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-IM"></div>
                    <div class="game-content-icon-title">IM</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-AG"></div>
                    <div class="game-content-icon-title">AG</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-BB"></div>
                    <div class="game-content-icon-title">BBIN</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-SBO"></div>
                    <div class="game-content-icon-title">SBO</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-ESB"></div>
                    <div class="game-content-icon-title">ESB</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-TF"></div>
                    <div class="game-content-icon-title">TF</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-AI"></div>
                    <div class="game-content-icon-title">AI</div>
                  </li>
                  <!-- <li class="text-center">
                    <div
                      class="game-content-icon game-content-icon-YB_esport"
                    ></div>
                    <div class="game-content-icon-title">亚博</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-BTI"></div>
                    <div class="game-content-icon-title">BTI</div>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="row">
            <div class="order-last col-lg-6 order-lg-first">
              <div class="game-content-bg game-content-bg-6"></div>
            </div>
            <div class="col-lg-6">
              <div :class="{ 'game-content-card': screenWidth < 992 }">
                <div class="game-content-line"></div>
                <h3 class="game-content-main game-content-main-6"></h3>
                <p class="game-content-sub">
                  ICG电子、PP电子、HC电子、FC电子、FG电子、BNG电子、MG电子、PG电子、CQ9电子、PT电子、BB电子、SW电子、AG电子、PS电子、RSG电子、JOKER电子、JILI电子、MW电子、Rich88电子、KA电子、JDB电子、GNS电子等一直是行业火热的游戏供应商，DOING包网携手多家厂商强强联手，将帮您最快速缔造自己的电子游艺平台，上百款游戏任由用户选择
                </p>
                <ul class="game-content-list">
                  <!-- <li class="text-center">
                    <div class="game-content-icon game-content-icon-918"></div>
                    <div class="game-content-icon-title">918KISS</div>
                  </li> -->
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-ICG"></div>
                    <div class="game-content-icon-title">ICG</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-PP"></div>
                    <div class="game-content-icon-title">PP</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-HC"></div>
                    <div class="game-content-icon-title">HC</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-FC"></div>
                    <div class="game-content-icon-title">FC</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-FG"></div>
                    <div class="game-content-icon-title">FG</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-BNG"></div>
                    <div class="game-content-icon-title">BNG</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-MG"></div>
                    <div class="game-content-icon-title">MG</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-PG"></div>
                    <div class="game-content-icon-title">PG</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-CQ9"></div>
                    <div class="game-content-icon-title">CQ9</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-PT"></div>
                    <div class="game-content-icon-title">PT</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-BB"></div>
                    <div class="game-content-icon-title">BB</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-SW"></div>
                    <div class="game-content-icon-title">SW</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-AG"></div>
                    <div class="game-content-icon-title">AG</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-PS"></div>
                    <div class="game-content-icon-title">PS</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-RSG"></div>
                    <div class="game-content-icon-title">RSG</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-JOKER"></div>
                    <div class="game-content-icon-title">JOKER</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-JILI"></div>
                    <div class="game-content-icon-title">JILI</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-MW"></div>
                    <div class="game-content-icon-title">MW</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-RICH88"></div>
                    <div class="game-content-icon-title">RICH88</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-KA"></div>
                    <div class="game-content-icon-title">KA</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-JDB"></div>
                    <div class="game-content-icon-title">JDB</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-GNS"></div>
                    <div class="game-content-icon-title">GNS</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="row">
            <div class="order-last col-lg-6 order-lg-first">
              <div class="game-content-bg game-content-bg-3"></div>
            </div>
            <div class="col-lg-6">
              <div :class="{ 'game-content-card': screenWidth < 992 }">
                <div class="game-content-line"></div>
                <h3 class="game-content-main game-content-main-3"></h3>
                <p class="game-content-sub">
                  开元棋牌、JDB棋牌、乐游棋牌、CQ9棋牌、MP棋牌、KX棋牌，不管是自研还是合作商，精致画质、流畅体验，都将帮您树立良好口碑，缔造精品平台
                </p>
                <ul class="game-content-list">
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-KY"></div>
                    <div class="game-content-icon-title">KY</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-JDB"></div>
                    <div class="game-content-icon-title">JDB</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-FG"></div>
                    <div class="game-content-icon-title">FG</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-CQ9"></div>
                    <div class="game-content-icon-title">CQ9</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-MP"></div>
                    <div class="game-content-icon-title">MP</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-KX"></div>
                    <div class="game-content-icon-title">KX</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="row">
            <div class="order-last col-lg-6 order-lg-first">
              <div class="game-content-bg game-content-bg-4"></div>
            </div>
            <div class="col-lg-6">
              <div :class="{ 'game-content-card': screenWidth < 992 }">
                <div class="game-content-line"></div>
                <h3 class="game-content-main game-content-main-4"></h3>
                <p class="game-content-sub">
                  最全面的彩种，最稳定的奖源，最丰富的玩法，最稳最快的开奖结果，是您拉新引流的最佳方式。我们还提供自研彩票，使您平台更彰显实力
                </p>
                <ul class="game-content-list">
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-AE"></div>
                    <div class="game-content-icon-title">AE</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-BB"></div>
                    <div class="game-content-icon-title">BBIN</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-IG"></div>
                    <div class="game-content-icon-title">IG</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-VR"></div>
                    <div class="game-content-icon-title">VR</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-GPI"></div>
                    <div class="game-content-icon-title">GPI</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-CLO888"></div>
                    <div class="game-content-icon-title">CLO888</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="row">
            <div class="order-last col-lg-6 order-lg-first">
              <div class="game-content-bg game-content-bg-2"></div>
            </div>
            <div class="col-lg-6">
              <div :class="{ 'game-content-card': screenWidth < 992 }">
                <div class="game-content-line"></div>
                <h3 class="game-content-main game-content-main-2"></h3>
                <p class="game-content-sub">
                  我们提供AG真人、AB真人、BB真人、BG真人、DG真人、EBET真人、EVO真人、EZUGI真人、MG真人、SA真人、WM真人、PP真人等业内最好的真人场馆游戏，精致画面、输赢公平，我们用信任，帮您赢得市场；用口碑，赢得用户信赖
                </p>
                <ul class="game-content-list">
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-AG"></div>
                    <div class="game-content-icon-title">AG</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-AB"></div>
                    <div class="game-content-icon-title">AB</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-BB"></div>
                    <div class="game-content-icon-title">BBIN</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-BG"></div>
                    <div class="game-content-icon-title">BG</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-DG"></div>
                    <div class="game-content-icon-title">DG</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-EBET"></div>
                    <div class="game-content-icon-title">EBET</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-EVO"></div>
                    <div class="game-content-icon-title">EVO</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-EZUGI"></div>
                    <div class="game-content-icon-title">EZUGI</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-MG"></div>
                    <div class="game-content-icon-title">MG</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-SA"></div>
                    <div class="game-content-icon-title">SA</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-WM"></div>
                    <div class="game-content-icon-title">WM</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-PP"></div>
                    <div class="game-content-icon-title">PP</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="row">
            <div class="order-last col-lg-6 order-lg-first">
              <div class="game-content-bg game-content-bg-5"></div>
            </div>
            <div class="col-lg-6">
              <div :class="{ 'game-content-card': screenWidth < 992 }">
                <div class="game-content-line"></div>
                <h3 class="game-content-main game-content-main-5"></h3>
                <p class="game-content-sub">
                  ESB电竞，行业最顶尖电竞赛事平台合作商，提供最新、最热门的电竞赛事竞猜，更有最热门电竞视频及最新电竞资讯等服务，帮您塑造品牌、提升热度
                </p>
                <ul class="game-content-list">
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-ESB"></div>
                    <div class="game-content-icon-title">ESB</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-YB_esport"></div>
                    <div class="game-content-icon-title">亚博</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-IBO"></div>
                    <div class="game-content-icon-title">皇冠</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-SB"></div>
                    <div class="game-content-icon-title">沙巴</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-NBB"></div>
                    <div class="game-content-icon-title">NBB</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-AG"></div>
                    <div class="game-content-icon-title">AG</div>
                  </li>
                  <li class="text-center">
                    <div class="game-content-icon game-content-icon-BB"></div>
                    <div class="game-content-icon-title">BBIN</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination swiper-mt" slot="pagination" v-if="screenWidth < 992"></div>
        <div class="swiper-button-prev" slot="button-prev" v-if="screenWidth < 992"></div>
        <div class="swiper-button-next" slot="button-next" v-if="screenWidth < 992"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  props: {
    slideData: {
      type: String,
      required: true,
    },
    screenWidth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      swiperOption: {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          stopOnLastSlide: false,
          waitForTransition: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      paginationNum: 0,
    };
  },
  computed: {
    tilteAnimate() {
      return {
        animate__animated: this.slideData === "game",
        animate__lightSpeedInLeft: this.slideData === "game",
        "opacity-0": this.slideData !== "game",
        "opacity-1": this.slideData === "game",
      };
    },
  },
  methods: {
    changePagination(i) {
      this.$refs.mySwiper.$swiper.slideTo(i, 800);
      this.paginationNum = i;
    },
  },
};
</script>