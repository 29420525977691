<template>
  <div class="container-xl cooperation-content h-75">
    <div
      v-if="screenWidth >= 992"
      class="cooperation-content-title d-flex align-items-end"
      :class="tilteAnimate"
    >
      <div class="d-flex">
        <div class="colon"></div>
        <div class="cooperation-content-title-icon page-title">商务合作</div>
      </div>
      <span class="words opacity-1 mb-1">轻松五步快速开站</span>
    </div>
    <div v-if="screenWidth >= 769">
      <div class="form-row align-items-center justify-content-between">
        <div class="col">
          <div class="cooperation-step cooperation-step1"
          :class="{
            'animate__animated':slideData === 'cooperation',
            'animate__pulse':slideData === 'cooperation',
          }"
          ></div>
        </div>
        <div class="col">
          <div class="cooperation-line"></div>
        </div>
        <div class="col">
          <div class="cooperation-step cooperation-step2"
          :class="{
            'animate__animated':slideData === 'cooperation',
            'animate__pulse':slideData === 'cooperation',
            'animate__delay-1s':slideData === 'cooperation'
          }"
          ></div>
        </div>
        <div class="col">
          <div class="cooperation-line"></div>
        </div>
        <div class="col">
          <div class="cooperation-step cooperation-step3"
          :class="{
            'animate__animated':slideData === 'cooperation',
            'animate__pulse':slideData === 'cooperation',
            'animate__delay-2s':slideData === 'cooperation'
          }"
          ></div>
        </div>
        <div class="col">
          <div class="cooperation-line"></div>
        </div>
        <div class="col">
          <div class="cooperation-step cooperation-step4"
          :class="{
            'animate__animated':slideData === 'cooperation',
            'animate__pulse':slideData === 'cooperation',
            'animate__delay-3s':slideData === 'cooperation'
          }"
          ></div>
        </div>
        <div class="col">
          <div class="cooperation-line"></div>
        </div>
        <div class="col">
          <div class="cooperation-step cooperation-step5"
          :class="{
            'animate__animated':slideData === 'cooperation',
            'animate__pulse':slideData === 'cooperation',
            'animate__delay-4s':slideData === 'cooperation'
          }"
          ></div>
        </div>
      </div>
      <div class="form-row align-items-center">
        <div class="col">
          <div class="mt-4 cooperation-words"
          :class="{
            'animate__animated':slideData === 'cooperation',
            'animate__pulse':slideData === 'cooperation',
          }"
          >
            <span class="cooperation-words-number words opacity-1"> 01 </span>
            <span class="cooperation-words-data words">意向需求洽谈</span>
          </div>
        </div>
        <div class="col"></div>
        <div class="col">
          <div class="mt-4 cooperation-words"
          :class="{
            'animate__animated':slideData === 'cooperation',
            'animate__pulse':slideData === 'cooperation',
            'animate__delay-1s':slideData === 'cooperation'
          }"
          >
            <span class="cooperation-words-number words opacity-1"> 02 </span>
            <span class="cooperation-words-data words">签订合作协议</span>
          </div>
        </div>
        <div class="col"></div>
        <div class="col">
          <div class="mt-4 cooperation-words"
          :class="{
            'animate__animated':slideData === 'cooperation',
            'animate__pulse':slideData === 'cooperation',
            'animate__delay-2s':slideData === 'cooperation'
          }"
          >
            <span class="cooperation-words-number words opacity-1"> 03 </span>
            <span class="cooperation-words-data words">建站细节沟通</span>
          </div>
        </div>
        <div class="col"></div>
        <div class="col">
          <div class="mt-4 cooperation-words"
          :class="{
            'animate__animated':slideData === 'cooperation',
            'animate__pulse':slideData === 'cooperation',
            'animate__delay-3s':slideData === 'cooperation'
          }"
          >
            <span class="cooperation-words-number words opacity-1"> 04 </span>
            <span class="cooperation-words-data words">平台站点部署</span>
          </div>
        </div>
        <div class="col"></div>
        <div class="col">
          <div class="mt-4 cooperation-words"
          :class="{
            'animate__animated':slideData === 'cooperation',
            'animate__pulse':slideData === 'cooperation',
            'animate__delay-4s':slideData === 'cooperation'
          }"
          >
            <span class="cooperation-words-number words opacity-1"> 05 </span>
            <span class="cooperation-words-data words">正式上线运营</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="">
        <div class="mb-2 d-flex justify-content-center align-items-center">
          <div class="mr-3 cooperation-step cooperation-step1"></div>
          <div class="cooperation-words">
            <span class="cooperation-words-number words opacity-1"> 01 </span>
            <span class="cooperation-words-data words">意向需求洽谈</span>
          </div>
        </div>
        <div class="mb-2 d-flex justify-content-center align-items-center">
          <div class="mr-3 cooperation-step cooperation-step2"></div>
          <div class="cooperation-words">
            <span class="cooperation-words-number words opacity-1"> 02 </span>
            <span class="cooperation-words-data words">签订合作协议</span>
          </div>
        </div>
        <div class="mb-2 d-flex justify-content-center align-items-center">
          <div class="mr-3 cooperation-step cooperation-step3"></div>
          <div class="cooperation-words">
            <span class="cooperation-words-number words opacity-1"> 03 </span>
            <span class="cooperation-words-data words">建站细节沟通</span>
          </div>
        </div>
        <div class="mb-2 d-flex justify-content-center align-items-center">
          <div class="mr-3 cooperation-step cooperation-step4"></div>
          <div class="cooperation-words">
            <span class="cooperation-words-number words opacity-1"> 04 </span>
            <span class="cooperation-words-data words">平台站点部署</span>
          </div>
        </div>
        <div class="mb-2 d-flex justify-content-center align-items-center">
          <div class="mr-3 cooperation-step cooperation-step5"></div>
          <div class="cooperation-words">
            <span class="cooperation-words-number words opacity-1"> 05 </span>
            <span class="cooperation-words-data words">正式上线运营</span>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <div class="cooperation-content-footer"></div>
      <a :href="telegram" target="_blank">
        <div class="btn rounded-pill cooperation-content-footer-btn">联系商务</div>
      </a>
      <div class="cooperation-content-footer-line"></div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="text-right mr-5 position-relative">
        <div @click="open(telegram)">
          <i class="tg-icon" ></i>
        <qrcode-vue :value="telegram" :size="'160'" level="Q" />
      </div>
      </div>
      <div class="position-relative">
        <div @click="open(skype)">
          <i class="skype-icon" ></i>
        <qrcode-vue :value="skype" :size="'160'" level="Q" @click="open(telegram)"/>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { getContactId } from '../../utils/query-string';

export default {
  name: 'Index',
  props: {
    slideData: {
      type: String,
      required: true
    },
    screenWidth: {
      type: Number,
      required: true,
    },
  },
  components: {
    QrcodeVue,
  },
  data() {
    return {
      id: getContactId(),
      telegram: '',
      skype: '',
    }
  },
  computed: {
    tilteAnimate() {
      return {
        'animate__animated': this.slideData === 'cooperation',
        'animate__lightSpeedInLeft': this.slideData === 'cooperation',
        'opacity-0':this.slideData !== 'cooperation',
        'opacity-1':this.slideData === 'cooperation'
      }
    }
  },
  mounted () {

      if (!this.id){
        return
      }
      fetch('/contact.json')
      .then(res=>res.json())
      .then(res=>{
        const info = res.find(e=>e.id == this.id);

        if(!info){
          return
        }
        this.telegram = info.tg
        this.skype = info.skype
      })
  },
  methods:{
    open:(link) => {
      if(!link) return
      link && window.open(link,'_blank')
    }
  }
};
</script>

<style lang="scss" scoped>
  .animate__animated.animate__pulse.animate__delay-1s {
    --animate-delay: 0.4s;
  }
  .animate__animated.animate__pulse.animate__delay-2s {
    --animate-delay: 0.4s;
  }
  .animate__animated.animate__pulse.animate__delay-3s {
    --animate-delay: 0.4s;
  }
  .animate__animated.animate__pulse.animate__delay-4s {
    --animate-delay: 0.4s;
  }
</style>

