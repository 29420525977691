<template>
  <nav class="navbar navbar-expand-lg header">
    <div class="container-xl">
      <a class="navbar-brand header-logo" @click="navbarActive('home')"></a>
      <div class="header-menu">
        <div v-if="headerName !== '' && screenWidth < 992" class="d-flex align-items-center">
          <span class="header-title">{{ headerName }}</span>
          <a class="rounded-pill nav-link header-btn" href="https://www.v588.co/" target="_blank">
            演示入口
          </a>
        </div>
        <div v-if="screenWidth < 992" class="header-hamburgur" @click.stop="
          hamburger = !hamburger;
        hambergerShow = !hambergerShow;
        ">
          <div class="header-hamburgur-icon" :class="{ 'header-hamburgur-icon-open': hamburger }"></div>
        </div>
      </div>
      <div v-if="screenWidth >= 992" class="collapse navbar-collapse" :id="navbarNav">
        <ul class="navbar-nav mr-auto w-100">
          <li class="nav-item">
            <div class="nav-link words" :class="{ active: slideData === 'home' }" @click="navbarActive('home')">
              <i class="home" :style="`background-image:url(${home1})`"></i>
              首页
            </div>
          </li>
          <li class="nav-item">
            <div class="nav-link words" :class="{ active: slideData === 'products' }" @click="navbarActive('products')">
              <i class="products" :style="`background-image:url(${product1})`"></i>
              极致产品
            </div>
          </li>
          <li class="nav-item">
            <div class="nav-link words" :class="{ active: slideData === 'game' }" @click="navbarActive('game')">
              <i class="game" :style="`background-image:url(${game1})`"></i>
              游戏中心
            </div>
          </li>
          <li class="nav-item">
            <div class="nav-link words" :class="{ active: slideData === 'technology' }"
              @click="navbarActive('technology')">
              <i class="technology" :style="`background-image:url(${tech1})`"></i>
              卓越技术
            </div>
          </li>
          <li class="nav-item">
            <div class="nav-link words" :class="{ active: slideData === 'service' }" @click="navbarActive('service')">
              <i class="service" :style="`background-image:url(${service1})`"></i>
              服务优势
            </div>
          </li>
          <li class="nav-item">
            <div class="nav-link words" :class="{ active: slideData === 'templateCase' }"
              @click="navbarActive('templateCase')">
              <i class="service" :style="`background-image:url(${case1})`"></i>
              模版案例
            </div>
          </li>
          <li class="nav-item" v-if="showContact">
            <div class="nav-link words" :class="{ active: slideData === 'cooperation' }"
              @click="navbarActive('cooperation')">
              <i class="cooperation" :style="`background-image:url(${coop1})`"></i>
              商务合作
            </div>
          </li>
          <li class="nav-item ml-auto">
            <a class="rounded-pill nav-link header-btn" href="https://www.v588.co/" target="_blank">
              演示入口
            </a>
          </li>
        </ul>
      </div>
      <div v-else class="header-hamburgur-main" :class="{ 'header-hamburgur-main-active': hambergerShow }" @click.stop="
        hamburger = !hamburger;
      hambergerShow = !hambergerShow;
      ">
        <ul>
          <li class="nav-item" :class="{ 'nav-item-active': slideData === 'home' }">
            <div data-target="#navbarNav" data-toggle="collapse" class="nav-link words"
              :class="{ active: slideData === 'home' }" @click="navbarActive('home')">
              <i class="home" :style="`background-image:url(${home1})`"></i>
              首页
            </div>
          </li>
          <li class="nav-item" :class="{ 'nav-item-active': slideData === 'products' }">
            <div data-target="#navbarNav" data-toggle="collapse" class="nav-link words"
              :class="{ active: slideData === 'products' }" @click="navbarActive('products')">
              <i class="products" :style="`background-image:url(${product1})`"></i>
              极致产品
            </div>
          </li>
          <li class="nav-item" :class="{ 'nav-item-active': slideData === 'game' }">
            <div data-target="#navbarNav" data-toggle="collapse" class="nav-link words"
              :class="{ active: slideData === 'game' }" @click="navbarActive('game')">
              <i class="game" :style="`background-image:url(${game1})`"></i>
              游戏中心
            </div>
          </li>
          <li class="nav-item" :class="{ 'nav-item-active': slideData === 'technology' }">
            <div data-target="#navbarNav" data-toggle="collapse" class="nav-link words"
              :class="{ active: slideData === 'technology' }" @click="navbarActive('technology')">
              <i class="technology" :style="`background-image:url(${tech1})`"></i>
              卓越技术
            </div>
          </li>
          <li class="nav-item" :class="{ 'nav-item-active': slideData === 'service' }">
            <div data-target="#navbarNav" data-toggle="collapse" class="nav-link words"
              :class="{ active: slideData === 'service' }" @click="navbarActive('service')">
              <i class="service" :style="`background-image:url(${service1})`"></i>
              服务优势
            </div>
          </li>
          <li class="nav-item">
            <div class="nav-link words" :class="{ active: slideData === 'templateCase' }"
              @click="navbarActive('templateCase')">
              <i class="service" :style="`background-image:url(${case1})`"></i>
              模版案例
            </div>
          </li>
          <li class="nav-item" :class="{ 'nav-item-active': slideData === 'cooperation' }" v-if="showContact">
            <div data-target="#navbarNav" data-toggle="collapse" class="nav-link words"
              :class="{ active: slideData === 'cooperation' }" @click="navbarActive('cooperation')">
              <i class="cooperation" :style="`background-image:url(${coop1})`"></i>
              商务合作
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import Logo from "@/assets/images/header/logo.png";
import home1 from "@/assets/images/header/icon1.png";
import coop1 from "@/assets/images/header/icon2.png";
import product1 from "@/assets/images/header/icon3.png";
import service1 from "@/assets/images/header/icon4.png";
import tech1 from "@/assets/images/header/icon5.png";
import game1 from "@/assets/images/header/icon6.png";
import case1 from "@/assets/images/header/icon7.png";
import { isshowContact } from '../../utils/query-string';

export default {
  name: "Navbar",
  props: {
    slideData: {
      type: String,
      required: true,
    },
    screenWidth: {
      type: Number,
      required: true,
    },
  },
  data() {
    const showContact = isshowContact();
    return {
      logo: Logo,
      home1: home1,
      coop1: coop1,
      product1: product1,
      service1: service1,
      tech1: tech1,
      game1: game1,
      case1: case1,
      navbartoggle: false,
      hamburger: false,
      hambergerShow: false,
      showContact,
    };
  },
  computed: {
    headerName() {
      switch (this.slideData) {
        case "home":
          return "";
        case "products":
          return "极致产品";
        case "game":
          return "游戏中心";
        case "technology":
          return "卓越技术";
        case "service":
          return "服务优势";
        case "templateCase":
          return "模版案例";
        case "cooperation":
          return "商务合作";
        default:
          return "";
      }
    },
    navbarNav() {
      if (this.screenWidth < 992) {
        return "navbarNav";
      } else {
        return "";
      }
    },
  },
  methods: {
    navbarActive(data) {
      this.$emit("navbarActive", data);
    }
  },
};
</script>
