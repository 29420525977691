<template>
  <div id="home">
    <swiper id="banner" ref="bannerSwiper" :options="bannerSwiperOption">
      <swiper-slide>
        <img :src="banner1" alt="">
      </swiper-slide>
      <swiper-slide>
        <img :src="banner2" alt="">
      </swiper-slide>
      <swiper-slide>
        <img :src="banner3" alt="">
      </swiper-slide>
      <div class="banner-swiper-pagination swiper-mt" slot="pagination"></div>
    </swiper>
    <div class="container-xl">
      <swiper class="home" ref="mySwiper" :options="swiperOption">
        <swiper-slide>
          <div class="home-bg1">
            <div v-if="screenWidth >= 992" class="home-bg1-web"></div>
            <div v-if="screenWidth >= 992"
              class="d-flex h-100 justify-content-lg-end justify-content-center align-items-lg-center align-items-end">
              <div class="home-bg1-texts" v-show="slideData === 'home'">
                <h1 class="home-bg1-title"
                  :class="{ 'animate__animated': slideData === 'home', 'animate__fadeInDown': slideData === 'home' }">
                </h1>
                <h3 class="home-bg1-h3"
                  :class="{ 'animate__animated': slideData === 'home', 'animate__fadeInDown': slideData === 'home' }">多语言
                  , 多币种
                </h3>
                <p class="home-bg1-p"
                  :class="{ 'animate__animated': slideData === 'home', 'animate__fadeInDown': slideData === 'home' }">
                  打造业内最强包网系统</p>
                <div class="text-center">
                  <a class="btn home-bg1-btn rounded-pill" href="https://www.v588.co/" target="_blank">演示入口</a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div v-if="screenWidth < 992" class="text-center">
              <h1 class="home-bg1-title"></h1>
              <h3 class="home-bg1-h3">多语言 , 多币种</h3>
              <p class="home-bg1-p">打造业内最强包网系统</p>
              <div class="text-center">
                <a class="btn home-bg1-btn rounded-pill" href="https://www.v588.co/" target="_blank">演示入口</a>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div v-if="screenWidth < 992" class="text-center">
            <div class="home-bg2-mobile">
              <h1 class="home-bg2-title mb-0 mb-lg-1"></h1>
              <h3 class="home-bg2-h3 mb-0"></h3>
              <p class="home-bg2-p mb-1 mb-lg-1">支持多国语言</p>
              <ul class="d-flex home-bg2-ul">
                <li class="mr-4">
                  <p class="home-bg2-ch home-bg2-icon"></p>
                  <span class="home-bg2-lang words">中国</span>
                </li>
                <li>
                  <p class="home-bg2-en home-bg2-icon"></p>
                  <span class="home-bg2-lang words">英国</span>
                </li>
              </ul>
              <div class="home-bg2-cus">
                <p class="text-right home-bg2-cus-title mb-0" v-if="screenWidth >= 992">支持多平台</p>
                <ul class="d-flex px-0">
                  <li class="mr-2 mr-md-3 mr-lg-5 d-flex align-items-center" v-if="screenWidth < 992">
                    <span class="words">支持多平台</span>
                  </li>
                  <li class="mr-2 mr-md-3 mr-lg-5 d-flex align-items-center">
                    <p class="home-bg2-cus-icon home-bg2-cus-android mb-0 mr-1"></p>
                    <span class="words">Android</span>
                  </li>
                  <li class="mr-2 mr-md-3 mr-lg-5 d-flex align-items-center">
                    <p class="home-bg2-cus-icon home-bg2-cus-ios mb-0 mr-1"></p>
                    <span class="words">IOS</span>
                  </li>
                  <li class="mr-2 mr-md-3 mr-lg-5 d-flex align-items-center">
                    <p class="home-bg2-cus-icon home-bg2-cus-h5 mb-0 mr-1"></p>
                    <span class="words">H5</span>
                  </li>
                  <li class="d-flex align-items-center">
                    <p class="home-bg2-cus-icon home-bg2-cus-web mb-0 mr-1"></p>
                    <span class="words">Web</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="home-bg2">
            <div v-if="screenWidth >= 992" class="home-bg2-web"></div>
            <div v-if="screenWidth >= 992"
              class="d-flex h-100 justify-content-lg-end justify-content-center align-items-lg-center align-items-end">
              <div>
                <h1 class="home-bg2-title"></h1>
                <h3 class="home-bg2-h3"></h3>
                <p class="home-bg2-p">支持多国语言</p>
                <ul class="d-flex home-bg2-ul">
                  <li class="mr-4">
                    <p class="home-bg2-ch home-bg2-icon"></p>
                    <span class="home-bg2-lang words">中国</span>
                  </li>
                  <li>
                    <p class="home-bg2-en home-bg2-icon"></p>
                    <span class="home-bg2-lang words">英国</span>
                  </li>
                </ul>
                <div class="home-bg2-cus">
                  <p class="text-right home-bg2-cus-title">支持多平台</p>
                  <ul class="d-flex">
                    <li class="mr-2 mr-md-3 mr-lg-5 d-flex align-items-center">
                      <p class="home-bg2-cus-icon home-bg2-cus-android mb-0 mr-1"></p>
                      <span class="words">Android</span>
                    </li>
                    <li class="mr-2 mr-md-3 mr-lg-5 d-flex align-items-center">
                      <p class="home-bg2-cus-icon home-bg2-cus-ios mb-0 mr-1"></p>
                      <span class="words">IOS</span>
                    </li>
                    <li class="mr-2 mr-md-3 mr-lg-5 d-flex align-items-center">
                      <p class="home-bg2-cus-icon home-bg2-cus-h5 mb-0 mr-1"></p>
                      <span class="words">H5</span>
                    </li>
                    <li class="d-flex align-items-center">
                      <p class="home-bg2-cus-icon home-bg2-cus-web mb-0 mr-1"></p>
                      <span class="words">Web</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination swiper-mt" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import banner1 from '@/assets/images/home/banner/1.png';
import banner2 from '@/assets/images/home/banner/2.png';
import banner3 from '@/assets/images/home/banner/3.png';

export default {
  name: 'Index',
  props: {
    slideData: {
      type: String,
      required: true
    },
    screenWidth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      banner1,
      banner2,
      banner3,
      swiperOption: {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          stopOnLastSlide: false,
          waitForTransition: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        observer: true,
        observeParents: true
      },
      bannerSwiperOption: {
        initialSlide: 1,
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 15,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          stopOnLastSlide: false,
          waitForTransition: true
        },
        navigation: {
          nextEl: ".banner-swiper-button-next",
          prevEl: ".banner-swiper-button-prev",
        },
        pagination: {
          el: '.banner-swiper-pagination',
          clickable: true
        },
        observer: true,
        observeParents: true
      },
    }
  },
}
</script>