import Vue from 'vue';
import App from './App.vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'bootstrap';
import 'swiper/css/swiper.css';
import 'fullpage.js/vendors/scrolloverflow';
import 'fullpage.js/dist/fullpage.min.css';
import VueFullPage from 'vue-fullpage.js';
import animated from 'animate.css';


Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper)
Vue.use(VueFullPage)
Vue.use(animated)

// window.$ = jquery

new Vue({
  render: h => h(App),
}).$mount('#app')
