<template>
  <div class="container-xl h-75">
    <div class="service-content">
      <div
        v-if="screenWidth >= 992"
        class="service-content-title d-flex align-items-end"
        :class="tilteAnimate"
      >
        <div class="d-flex">
          <div class="colon"></div>
          <div class="service-content-title-icon page-title">服务优势</div>
        </div>
        <span class="words opacity-1 mb-1">致力为客户提供全方位服务</span>
      </div>
      <div :class="{ row: screenWidth >= 992, 'form-row': screenWidth < 992 }">
        <div
          class="service-col col-6 col-lg-4"
          :class="{
            animate__animated: slideData === 'service' && screenWidth >= 992,
            animate__flipInX: slideData === 'service' && screenWidth >= 992,
            'opacity-0': slideData !== 'service' && screenWidth >= 992,
            'opacity-1': slideData === 'service' && screenWidth >= 992,
          }"
        >
          <div class="service-card">
            <div class="service-card-logo service-card-industryicon"></div>
            <h3 class="service-card-title opacity-1">业内口碑 值得信赖</h3>
            <div class="words">
              <p class="service-card-data">
                精选六大品牌场馆，打造全新娱乐体验，让您品牌脱颖而出
              </p>
            </div>
          </div>
        </div>
        <div
          class="service-col col-6 col-lg-4"
          :class="{
            animate__animated: slideData === 'service' && screenWidth >= 992,
            animate__flipInX: slideData === 'service' && screenWidth >= 992,
            'animate__delay-1s': slideData === 'service' && screenWidth >= 992,
            'opacity-0': slideData !== 'service' && screenWidth >= 992,
            'opacity-1': slideData === 'service' && screenWidth >= 992,
          }"
        >
          <div class="service-card">
            <div class="service-card-logo service-card-exclusiveicon"></div>
            <h3 class="service-card-title opacity-1">专属服务 省心快速</h3>
            <div class="words">
              <p class="service-card-data">
                专属VIP服务团队，专家级一对一指导运营，个性化服务我们更加专业
              </p>
            </div>
          </div>
        </div>
        <div
          class="service-col col-6 col-lg-4"
          :class="{
            animate__animated: slideData === 'service' && screenWidth >= 992,
            animate__flipInX: slideData === 'service' && screenWidth >= 992,
            'animate__delay-2s': slideData === 'service' && screenWidth >= 992,
            'opacity-0': slideData !== 'service' && screenWidth >= 992,
            'opacity-1': slideData === 'service' && screenWidth >= 992,
          }"
        >
          <div class="service-card">
            <div class="service-card-logo service-card-teamworkicon"></div>
            <h3 class="service-card-title opacity-1">合作模式 独具一格</h3>
            <div class="words">
              <p class="service-card-data">
                DOING包网创新合作模式，自定义版面，专业搭建，7/24全年无维修，精雕细琢打造您的专属品牌
              </p>
            </div>
          </div>
        </div>
        <div
          class="service-col col-6 col-lg-4"
          :class="{
            animate__animated: slideData === 'service' && screenWidth >= 992,
            animate__flipInX: slideData === 'service' && screenWidth >= 992,
            'animate__delay-3s': slideData === 'service' && screenWidth >= 992,
            'opacity-0': slideData !== 'service' && screenWidth >= 992,
            'opacity-1': slideData === 'service' && screenWidth >= 992,
          }"
        >
          <div class="service-card">
            <div class="service-card-logo service-card-financeicon"></div>
            <h3 class="service-card-title opacity-1">创新财务 安全无忧</h3>
            <div class="words">
              <p class="service-card-data">
                创新引领行业技术+财务服务，使平台业务、资金，运作安全、更高效
              </p>
            </div>
          </div>
        </div>
        <div
          class="service-col col-6 col-lg-4"
          :class="{
            animate__animated: slideData === 'service' && screenWidth >= 992,
            animate__flipInX: slideData === 'service' && screenWidth >= 992,
            'animate__delay-4s': slideData === 'service' && screenWidth >= 992,
            'opacity-0': slideData !== 'service' && screenWidth >= 992,
            'opacity-1': slideData === 'service' && screenWidth >= 992,
          }"
        >
          <div class="service-card">
            <div class="service-card-logo service-card-availableicon"></div>
            <h3 class="service-card-title opacity-1">专业技术团队 运营无忧</h3>
            <div class="words">
              <p class="service-card-data">
                高规格前后台全客户定制、高质客服服务，玩法升级，完美体验高效率、高利润
              </p>
            </div>
          </div>
        </div>
        <div
          class="service-col col-6 col-lg-4"
          :class="{
            animate__animated: slideData === 'service' && screenWidth >= 992,
            animate__flipInX: slideData === 'service' && screenWidth >= 992,
            'animate__delay-5s': slideData === 'service' && screenWidth >= 992,
            'opacity-0': slideData !== 'service' && screenWidth >= 992,
            'opacity-1': slideData === 'service' && screenWidth >= 992,
          }"
        >
          <div class="service-card">
            <div class="service-card-logo service-card-overallicon"></div>
            <h3 class="service-card-title opacity-1">全面扶持 鼎力相助</h3>
            <div class="words">
              <p class="service-card-data">
                DOING包网为平台建立初期提供高额助力资金，帮助平台用户量快速增长、盈利
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  props: {
    slideData: {
      type: String,
      required: true,
    },
    screenWidth: {
      type: Number,
      required: true,
    },
  },
  computed: {
    tilteAnimate() {
      return {
        animate__animated: this.slideData === "service",
        animate__lightSpeedInLeft: this.slideData === "service",
        "opacity-0": this.slideData !== "service",
        "opacity-1": this.slideData === "service",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.animate__animated.animate__flipInX.animate__delay-1s {
  --animate-delay: 0.5s;
}
.animate__animated.animate__flipInX.animate__delay-2s {
  --animate-delay: 0.5s;
}
.animate__animated.animate__flipInX.animate__delay-3s {
  --animate-delay: 0.5s;
}
.animate__animated.animate__flipInX.animate__delay-4s {
  --animate-delay: 0.5s;
}
.animate__animated.animate__flipInX.animate__delay-5s {
  --animate-delay: 0.5s;
}
</style>
