export const getQueryString = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params;
}

export const isshowContact = () => {
  const query = getQueryString();
  return query.contact != 0
}

export const getContactId = () => {
  const query = getQueryString();
  return query.contact || '-1'
}