<template>
  <div id="app">
    <Navbar :slideData="slideData" :screenWidth="screenWidth" @navbarActive="navbarActive" />
    <div>
      <full-page :options="options" id="fullpage" ref="fullpage">
        <div class="section bg-cover" :style="`background-image:url(${bg})`">
            <Home :slideData="slideData" :screenWidth="screenWidth" />
        </div>
        <div class="section bg-cover" :style="`background-image:url(${bg2})`">
            <Products :slideData="slideData" :screenWidth="screenWidth" />
        </div>
        <div class="section bg-cover" :style="`background-image:url(${bg})`">
            <Game :slideData="slideData" :screenWidth="screenWidth" />
        </div>
        <div class="section bg-cover" :style="`background-image:url(${bg2})`">
            <Technology :slideData="slideData" :screenWidth="screenWidth" />
        </div>
        <div class="section bg-cover" :style="`background-image:url(${bg})`">
            <Service :slideData="slideData" :screenWidth="screenWidth" />
        </div>
        <div class="section bg-cover" :style="`background-image:url(${bg2})`">
            <TemplateCase :slideData="slideData" :screenWidth="screenWidth" @openModal="openModal" />
        </div>
        <div v-if="showContact" class="section bg-cover" :style="`background-image:url(${bg})`">
            <Cooperation :slideData="slideData" :screenWidth="screenWidth"  />
        </div>
      </full-page>
    </div>
    <div class="modal fade bd-example-modal-xl" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog  modal-xl" :class="{'modal-dialog-centered' : screenWidth < 992 }" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ modal.title }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <img :src="modal.image" style="width:100%">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import background from '@/assets/images/bg1.jpg';
import background2 from '@/assets/images/bg2.jpg';
import Navbar from '@/components/layout/Navbar.vue';
import Home from '@/components/home/Index.vue';
import Products from '@/components/products/Index.vue'
import Game from '@/components/game/Index.vue';
import Technology from '@/components/technology/Index.vue';
import Service from '@/components/service/Index.vue';
import Cooperation from '@/components/cooperation/Index.vue';
import TemplateCase from '@/components/templateCase/Index.vue';
import templateCaseimage1 from '@/assets/images/templateCase/01.jpg';
import templateCaseimage2 from '@/assets/images/templateCase/02.jpg';
import templateCaseimage3 from '@/assets/images/templateCase/03.jpg';
import templateCaseimage4 from '@/assets/images/templateCase/04.jpg';
import templateCaseimage5 from '@/assets/images/templateCase/05.jpg';
import templateCaseimage6 from '@/assets/images/templateCase/06.jpg';
import templateCaseimage7 from '@/assets/images/templateCase/07.jpg';
import templateCaseimage8 from '@/assets/images/templateCase/08.jpg';
import templateCaseimage9 from '@/assets/images/templateCase/09.jpg';
import templateCaseimage10 from '@/assets/images/templateCase/10.jpg';
import templateCaseimage11 from '@/assets/images/templateCase/11.jpg';
import templateCaseimage12 from '@/assets/images/templateCase/12.jpg';
import templateCaseimage13 from '@/assets/images/templateCase/13.jpg';
import templateCaseimage14 from '@/assets/images/templateCase/14.jpg';
import templateCaseimage15 from '@/assets/images/templateCase/15.jpg';
import templateCaseimage16 from '@/assets/images/templateCase/16.jpg';
import templateCaseimage17 from '@/assets/images/templateCase/17.jpg';
import templateCaseimage18 from '@/assets/images/templateCase/18.jpg';
import templateCaseimage19 from '@/assets/images/templateCase/19.jpg';
import templateCaseimage20 from '@/assets/images/templateCase/20.jpg';
import { isshowContact } from './utils/query-string';

export default {
  name: 'App',
  components: {
    Navbar,
    Home,
    Products,
    Game,
    Technology,
    Service,
    Cooperation,
    TemplateCase
  },
  data() {
    const showContact = isshowContact();
    const anchors = ['home', 'products', 'game', 'technology', 'service', 'templateCase'];
    const navigationTooltips = ['首页', '极致产品', '游戏中心', '卓越技术', '服务优势', '模版案例'];
    if(showContact){
      navigationTooltips.push('商务合作')
      anchors.push('cooperation')
    }
    return {
      showContact,
      options: {
          afterLoad: this.afterLoad,
          navigation: true,
          verticalCentered: true,
          touchSensitivity:20,
          anchors,
          navigationTooltips,
      },
      slideData: '',
      bg: background,
      bg2: background2,
      screenWidth: document.body.clientWidth,
      modal: {
        title:'',
        image: '',
      }
    }
  },
  methods: {
    checkScreenWidth() {
      if(this.screenWidth < 992) {
      this.options.navigation = false
      }else {
        this.options.navigation = true
      }
    },
    afterLoad(origin, destination) {
      this.slideData = destination.anchor
    },
    navbarActive(data) {
      this.slideData = data
      switch (data) {
        case 'home':
        this.$refs.fullpage.api.moveTo(1)
        break
        case 'products':
        this.$refs.fullpage.api.moveTo(2)
        break
        case 'game':
        this.$refs.fullpage.api.moveTo(3)
        break
        case 'technology':
        this.$refs.fullpage.api.moveTo(4)
        break
        case 'service':
        this.$refs.fullpage.api.moveTo(5)
        break
        case 'templateCase':
        this.$refs.fullpage.api.moveTo(6)
        break
        case 'cooperation':
        this.$refs.fullpage.api.moveTo(7)
        break
      }
    },
    openModal(num) {
      this.modal.title = `DOING-${num}`
      switch (num) {
        case '01':
        this.modal.image = templateCaseimage1
        break
        case '02':
        this.modal.image = templateCaseimage2
        break
        case '03':
        this.modal.image = templateCaseimage3
        break
        case '04':
        this.modal.image = templateCaseimage4
        break
        case '05':
        this.modal.image = templateCaseimage5
        break
        case '06':
        this.modal.image = templateCaseimage6
        break
        case '07':
        this.modal.image = templateCaseimage7
        break
        case '08':
        this.modal.image = templateCaseimage8
        break
        case '09':
        this.modal.image = templateCaseimage9
        break
        case '10':
        this.modal.image = templateCaseimage10
        break
        case '11':
        this.modal.image = templateCaseimage11
        break
        case '12':
        this.modal.image = templateCaseimage12
        break
        case '13':
        this.modal.image = templateCaseimage13
        break
        case '14':
        this.modal.image = templateCaseimage14
        break
        case '15':
        this.modal.image = templateCaseimage15
        break
        case '16':
        this.modal.image = templateCaseimage16
        break
        case '17':
        this.modal.image = templateCaseimage17
        break
        case '18':
        this.modal.image = templateCaseimage18
        break
        case '19':
        this.modal.image = templateCaseimage19
        break
        case '20':
        this.modal.image = templateCaseimage20
        break
      }
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        this.screenWidth = window.screenWidth
      })()
    }
    this.checkScreenWidth()
  },
  watch: {
    screenWidth(val) {
      if(!this.timer){
        this.screenWidth = val
        if(this.screenWidth < 992) {
          this.options.navigation = false
        }else {
          this.options.navigation = true
        }
        this.timer = true
        let vm = this
        setTimeout(function(){
            vm.timer = false
        },400)
      }
    },
  }
};
</script>

<style lang="scss">
@import "./assets/scss/all.scss";
</style>
