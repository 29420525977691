<template>
  <div class="container-xl h-75 templateCase">
    <div class="templateCase-content">
      <div
        v-if="screenWidth >= 992"
        class="templateCase-content-title d-flex align-items-end"
        :class="tilteAnimate"
      >
        <div class="d-flex">
          <div class="colon"></div>
          <div class="templateCase-content-title-icon page-title">模版案例</div>
        </div>
        <span class="words opacity-1 mb-1">高端客制版型选择众多</span>
      </div>
    </div>
    <swiper class="templateCase-swiper" ref="mySwiper" :options="swiperOption">
      <template v-if="screenWidth >= 768">
        <swiper-slide>
          <div class="form-row">
            <div class="col-md-4 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox1">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('01')" >Details</a>
                </div>
                <div>
                  <span>DOING-01</span>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-red-d"></i>
                </div>
              </div>
            </div>
            <div class="col-md-4 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox2">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('02')" >Details</a>
                </div>
                <div>
                  <span>DOING-02</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-blue-l"></i>
                </div>
              </div>
            </div>
            <div class="col-md-4 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox3">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('03')" >Details</a>
                </div>
                <div>
                  <span>DOING-03</span>
                  <i class="templateCase-card-yellow-d"></i>
                  <i class="templateCase-card-green-d"></i>
                </div>
              </div>
            </div>
            <div class="col-md-4 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox4">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('04')" >Details</a>
                </div>
                <div>
                  <span>DOING-04</span>
                  <i class="templateCase-card-yellow-d"></i>
                  <i class="templateCase-card-purple-l"></i>
                </div>
              </div>
            </div>
            <div class="col-md-4 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox5">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('05')" >Details</a>
                </div>
                <div>
                  <span>DOING-05</span>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-blue-d"></i>
                </div>
              </div>
            </div>
            <div class="col-md-4 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox6">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('06')" >Details</a>
                </div>
                <div>
                  <span>DOING-06</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-orange-l"></i>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="form-row">
            <div class="col-md-4 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox7">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('07')" >Details</a>
                </div>
                <div>
                  <span>DOING-07</span>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-brown"></i>
                  <i class="templateCase-card-gold"></i>
                </div>
              </div>
            </div>
            <div class="col-md-4 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox8">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('08')" >Details</a>
                </div>
                <div>
                  <span>DOING-08</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-yellow-d"></i>
                </div>
              </div>
            </div>
            <div class="col-md-4 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox9">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('09')" >Details</a>
                </div>
                <div>
                  <span>DOING-09</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-red-l"></i>
                  <i class="templateCase-card-blue-d"></i>
                </div>
              </div>
            </div>
            <div class="col-md-4 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox10">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('10')" >Details</a>
                </div>
                <div>
                  <span>DOING-10</span>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-blue-d"></i>
                  <i class="templateCase-card-orange-d"></i>
                </div>
              </div>
            </div>
            <div class="col-md-4 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox11">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('11')" >Details</a>
                </div>
                <div>
                  <span>DOING-11</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-lndigo-l"></i>
                  <i class="templateCase-card-red-l"></i>
                  <i class="templateCase-card-green-l"></i>
                </div>
              </div>
            </div>
            <div class="col-md-4 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox12">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('12')" >Details</a>
                </div>
                <div>
                  <span>DOING-12</span>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-brown"></i>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="form-row">
            <div class="col-md-4 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox13">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('13')" >Details</a>
                </div>
                <div>
                  <span>DOING-13</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-lndigo-d"></i>
                  <i class="templateCase-card-lndigo-l"></i>
                </div>
              </div>
            </div>
            <div class="col-md-4 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox14">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('14')" >Details</a>
                </div>
                <div>
                  <span>DOING-14</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-red-d"></i>
                </div>
              </div>
            </div>
            <div class="col-md-4 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox15">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('15')" >Details</a>
                </div>
                <div>
                  <span>DOING-15</span>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-brown"></i>
                  <i class="templateCase-card-yellow-d"></i>
                </div>
              </div>
            </div>
            <div class="col-md-4 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox16">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('16')" >Details</a>
                </div>
                <div>
                  <span>DOING-16</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-red-d"></i>
                </div>
              </div>
            </div>
            <div class="col-md-4 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox17">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('17')" >Details</a>
                </div>
                <div>
                  <span>DOING-17</span>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-lndigo-d"></i>
                  <i class="templateCase-card-lndigo-l"></i>
                </div>
              </div>
            </div>
            <div class="col-md-4 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox18">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('18')" >Details</a>
                </div>
                <div>
                  <span>DOING-18</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-red-d"></i>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="form-row">
            <div class="col-md-4 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox19">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('19')" >Details</a>
                </div>
                <div>
                  <span>DOING-19</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-lndigo-d"></i>
                  <i class="templateCase-card-lndigo-l"></i>
                </div>
              </div>
            </div>
            <div class="col-md-4 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox20">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('20')" >Details</a>
                </div>
                <div>
                  <span>DOING-20</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-red-d"></i>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </template>
      <template v-else>
        <swiper-slide>
          <div class="row">
            <div class="col-12 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox1">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('01')" >Details</a>
                </div>
                <div>
                  <span>DOING-01</span>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-red-d"></i>
                </div>
              </div>
            </div>
            <div class="col-12 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox2">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('02')" >Details</a>
                </div>
                <div>
                  <span>DOING-02</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-blue-l"></i>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="row">
            <div class="col-12 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox3">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('03')" >Details</a>
                </div>
                <div>
                  <span>DOING-03</span>
                  <i class="templateCase-card-yellow-d"></i>
                  <i class="templateCase-card-green-d"></i>
                </div>
              </div>
            </div>
            <div class="col-12 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox4">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('04')" >Details</a>
                </div>
                <div>
                  <span>DOING-04</span>
                  <i class="templateCase-card-yellow-d"></i>
                  <i class="templateCase-card-purple-l"></i>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="row">
            <div class="col-12 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox5">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('05')" >Details</a>
                </div>
                <div>
                  <span>DOING-05</span>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-blue-d"></i>
                </div>
              </div>
            </div>
            <div class="col-12 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox6">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('06')" >Details</a>
                </div>
                <div>
                  <span>DOING-06</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-orange-l"></i>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="row">
            <div class="col-12 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox7">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('07')" >Details</a>
                </div>
                <div>
                  <span>DOING-07</span>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-brown"></i>
                  <i class="templateCase-card-gold"></i>
                </div>
              </div>
            </div>
            <div class="col-12 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox8">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('08')" >Details</a>
                </div>
                <div>
                  <span>DOING-08</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-yellow-d"></i>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="row">
            <div class="col-12 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox9">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('09')" >Details</a>
                </div>
                <div>
                  <span>DOING-09</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-red-l"></i>
                  <i class="templateCase-card-blue-d"></i>
                </div>
              </div>
            </div>
            <div class="col-12 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox10">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('10')" >Details</a>
                </div>
                <div>
                  <span>DOING-10</span>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-blue-d"></i>
                  <i class="templateCase-card-orange-d"></i>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="row">
            <div class="col-12 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox11">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('11')" >Details</a>
                </div>
                <div>
                  <span>DOING-11</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-lndigo-l"></i>
                  <i class="templateCase-card-red-l"></i>
                  <i class="templateCase-card-green-l"></i>
                </div>
              </div>
            </div>
            <div class="col-12 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox12">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('12')" >Details</a>
                </div>
                <div>
                  <span>DOING-12</span>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-brown"></i>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="row">
            <div class="col-12 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox13">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('13')" >Details</a>
                </div>
                <div>
                  <span>DOING-13</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-lndigo-d"></i>
                  <i class="templateCase-card-lndigo-l"></i>
                </div>
              </div>
            </div>
            <div class="col-12 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox14">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('14')" >Details</a>
                </div>
                <div>
                  <span>DOING-14</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-red-d"></i>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="row">
            <div class="col-12 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox15">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('15')" >Details</a>
                </div>
                <div>
                  <span>DOING-15</span>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-brown"></i>
                  <i class="templateCase-card-yellow-d"></i>
                </div>
              </div>
            </div>
            <div class="col-12 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox16">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('16')" >Details</a>
                </div>
                <div>
                  <span>DOING-16</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-red-d"></i>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="row">
            <div class="col-12 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox17">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('17')" >Details</a>
                </div>
                <div>
                  <span>DOING-17</span>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-lndigo-d"></i>
                  <i class="templateCase-card-lndigo-l"></i>
                </div>
              </div>
            </div>
            <div class="col-12 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox18">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('18')" >Details</a>
                </div>
                <div>
                  <span>DOING-18</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-red-d"></i>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="row">
            <div class="col-12 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox19">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('19')" >Details</a>
                </div>
                <div>
                  <span>DOING-19</span>
                  <i class="templateCase-card-black"></i>
                  <i class="templateCase-card-lndigo-d"></i>
                  <i class="templateCase-card-lndigo-l"></i>
                </div>
              </div>
            </div>
            <div class="col-12 templateCase-col">
              <div class="templateCase-card">
                <div class="templateCase-card-imagebox templateCase-card-imagebox20">
                  <a data-toggle="modal" data-target="#myModal" @click="openModal('20')" >Details</a>
                </div>
                <div>
                  <span>DOING-20</span>
                  <i class="templateCase-card-white"></i>
                  <i class="templateCase-card-red-d"></i>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </template>
      <div class="swiper-pagination swiper-mt" slot="pagination"></div>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
  </div>
</template>

<script>
export default {
  name: 'Index',
  props: {
    slideData: {
      type: String,
      required: true
    },
    screenWidth: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      swiperOption: {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          stopOnLastSlide:false,
          waitForTransition: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          hideOnClick: true
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        loop:true
      },
    }
  },
  computed: {
    tilteAnimate() {
      return {
        'animate__animated': this.slideData === 'templateCase',
        'animate__lightSpeedInLeft': this.slideData === 'templateCase',
        'opacity-0':this.slideData !== 'templateCase',
        'opacity-1':this.slideData === 'templateCase'
      }
    }
  },
  methods: {
    openModal(num) {
      this.$emit('openModal', num)
    }
  },
}
</script>