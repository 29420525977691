<template>
  <div class="container-xl h-75">
    <div class="technology-content">
      <div v-if="screenWidth >= 992"
        class="technology-content-title d-flex align-items-end"
        :class="tilteAnimate"
        >
        <div class="d-flex">
          <div class="colon"></div>
          <div class="technology-content-title-icon page-title">卓越技术</div>
        </div>
        <span class="words opacity-1 mb-1">顶级技术加持 为您保驾护航</span>
      </div>
      <div>
        <div class="row">
          <div class="col-lg-6 technology-col"
          :class="{
            'animate__animated': slideData === 'technology' && screenWidth >= 992,
            'animate__flipInX': slideData === 'technology' && screenWidth >= 992,
            'opacity-0':slideData !== 'technology' && screenWidth >= 992,
            'opacity-1':slideData === 'technology' && screenWidth >= 992
          }"
          >
            <div class="technology-card">
              <div class="d-flex justify-content-between align-items-center">
                <div class="technology-card-safety flex-fill">
                  <h3 class="words opacity-1">顶级机房</h3>
                </div>
                <div class="technology-card-logo technology-card-severlogo"></div>
              </div>
              <div class="words opacity-1 mt-2">
                <p class="technology-card-data">
                  顶级服务器硬件配置，提供数据的安全、可靠性。RESTful
                  API实现在互联网任何位置存储和访问
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 technology-col"
          :class="{
            'animate__animated': slideData === 'technology' && screenWidth >= 992,
            'animate__flipInX': slideData === 'technology' && screenWidth >= 992,
            'animate__delay-1s': slideData === 'technology' && screenWidth >= 992,
            'opacity-0':slideData !== 'technology' && screenWidth >= 992,
            'opacity-1':slideData === 'technology' && screenWidth >= 992
          }"
          >
            <div class="technology-card">
              <div class="d-flex justify-content-between align-items-center">
                <div class="technology-card-safety flex-fill">
                  <h3 class="words opacity-1">安全防护</h3>
                </div>
                <div class="technology-card-logo technology-card-safetylogo"></div>
              </div>
              <div class="words opacity-1 mt-2">
                <p class="technology-card-data">
                  自研CDN高防系统，全天候360度安全防护，提供1T超大防护宽带，有效防御T级DDOS攻击及百万级CC并发攻击
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 technology-col"
          :class="{
            'animate__animated': slideData === 'technology' && screenWidth >= 992,
            'animate__flipInX': slideData === 'technology' && screenWidth >= 992,
            'animate__delay-2s': slideData === 'technology' && screenWidth >= 992,
            'opacity-0':slideData !== 'technology' && screenWidth >= 992,
            'opacity-1':slideData === 'technology' && screenWidth >= 992
          }"
          >
            <div class="technology-card">
              <div class="d-flex justify-content-between align-items-center">
                <div class="technology-card-safety flex-fill">
                  <h3 class="words opacity-1">专属线路</h3>
                </div>
                <div class="technology-card-logo technology-card-exclusivelogo"></div>
              </div>
              <div class="words opacity-1 mt-2">
                <p class="technology-card-data">
                  专属光纤线路，直达大陆、菲律宾、香港、台湾、日本等重要业务市场，高速网络遍布东南亚，超快超稳
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 technology-col"
          :class="{
            'animate__animated': slideData === 'technology' && screenWidth >= 992,
            'animate__flipInX': slideData === 'technology' && screenWidth >= 992,
            'animate__delay-3s': slideData === 'technology' && screenWidth >= 992,
            'opacity-0':slideData !== 'technology' && screenWidth >= 992,
            'opacity-1':slideData === 'technology' && screenWidth >= 992
          }"
          >
            <div class="technology-card">
              <div class="d-flex justify-content-between align-items-center">
                <div class="technology-card-safety flex-fill">
                  <h3 class="words opacity-1">防劫持系统</h3>
                </div>
                <div class="technology-card-logo technology-card-systemlogo"></div>
              </div>
              <div class="words opacity-1 mt-2">
                <p class="technology-card-data">
                  自研防劫持浏览器和底层验权算法，通讯链路采用军用级加密算法，确保客户端和服务端通讯安全可靠
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  props: {
    slideData: {
      type: String,
      required: true
    },
    screenWidth: {
      type: Number,
      required: true
    }
  },
  computed: {
    tilteAnimate() {
      return {
        'animate__animated': this.slideData === 'technology',
        'animate__lightSpeedInLeft': this.slideData === 'technology',
        'opacity-0':this.slideData !== 'technology',
        'opacity-1':this.slideData === 'technology'
      }
    },
  }
};
</script>

<style lang="scss" scoped>
  .animate__animated.animate__flipInX.animate__delay-1s {
    --animate-delay: 0.5s;
  }
  .animate__animated.animate__flipInX.animate__delay-2s {
    --animate-delay: 0.5s;
  }
  .animate__animated.animate__flipInX.animate__delay-3s {
    --animate-delay: 0.5s;
  }
</style>